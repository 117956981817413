<template>
  <div
    v-if="show"
    :class="[
      $style.notificationItem,
      $style[`notificationItem${notification.type}`],
      (AnaliticsClasses.notification[notification.type] || ''),
      {
        ['awSrOnly']: notification.screenReaderOnly,
        [$style.notificationItemMarginLess]: notification.screenReaderOnly || isMarginLess,
      },
    ]"
  >
    <div data-notification-content :class="[$style.notificationItemContent, $style[contentAlignment], {[$style.notificationItemContentThin]: thinContent, [$style.notificationItemContentExtraThin]: extraThinContent, [$style.notificationItemContentThick]: thickContent, [$style.notificationItemContentInlined]: inlinedContent}]">
      <lv-grid :type="thickContent ? 'column' : 'row'" gutter="xs" align-items="center">
        <lv-grid type="item" xs="min" :align-self="thickContent ? 'center' : 'start'">
          <div :class="[{[$style.notificationItemCircle]: thickContent}, $style[notification.type] ]">
            <lv-icon
              :class="[$style.notificationItemIcon, $style[`notificationItemIcon${notification.type}`], {[$style.notificationItemIconThick]: thickContent}]"
              :name="notification.iconName"
              :size="24"
              :fill="false"
            />
          </div>
        </lv-grid>
        <lv-grid type="item" xs="expand">
          <slot name="title" :notification-item-style="$style">
            <div v-if="notificationTexts.title && !thickContent" :class="$style.notificationItemContentTitle" v-html="notificationTexts.title" />
          </slot>
          <div :class="[$style.notificationItemContentSubtitle]">
            <slot name="subtitle" :notification-item-style="$style" :processed-subtitle="notificationTexts.subtitle">
              <div v-if="notificationTexts.subtitle" v-html="notificationTexts.subtitle" />
            </slot>
          </div>
          <slot name="footer" :notification-item-style="$style">
            <div v-if="notificationTexts.footer" :class="$style.notificationItemContentFooter" v-html="notificationTexts.footer" />
          </slot>
          <div v-if="itemErrors && itemErrors.length" :class="$style.productWrapper">
            <aw-basket-product-list-item
              v-for="(item, i) in itemErrors"
              :key="item.referenceId"
              :quantity="item.quantity"
              :product-item="item"
              :show-price="false"
              :show-delete="false"
              :hide-bottom-separator="i === itemErrors.length - 1"
              quantity-read-only
              is-error
            />
          </div>
          <client-only>
            <div v-if="notification.promise">
              <div :class="$style.actionButtons">
                <lv-button
                  v-for="(v, k) in notification.promise"
                  :key="k"
                  type="button"
                  :styling="v.styling || 'small-primary-dark'"
                  :shrink="screenRange['tablet-min']"
                  :stretch="screenRange['mobile-max']"
                  @click="resolveClose(k)"
                >
                  {{ v.textToken ? $awt(v.textToken) : v.textString }}
                </lv-button>
              </div>
            </div>
            <div v-if="notification.apiData">
              <div :class="$style.actionButtons">
                <lv-button
                  type="button"
                  styling="small-primary-dark"
                  :shrink="screenRange['tablet-min']"
                  :stretch="screenRange['mobile-max']"
                  @click="callAction"
                >
                  {{ $awt('aw.global.ok') }}
                </lv-button>
                <lv-button
                  type="button"
                  styling="small-secondary-dark"
                  :shrink="screenRange['tablet-min']"
                  :stretch="screenRange['mobile-max']"
                  @click="closeSelf"
                >
                  {{ $awt('aw.global.cancel') }}
                </lv-button>
              </div>
            </div>
          </client-only>
        </lv-grid>
      </lv-grid>
      <button v-if="notification.manualClose" :class="[$style.notificationItemClose, 'awFocusV']" @click="resolveClose('close')">
        <lv-icon name="close-16" :size="16" />
      </button>
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState } from 'pinia';
  import { LvGrid, LvIcon, LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AnaliticsClasses from '~~/common/config/AnaliticsClasses';
  import { notificationTokenizer } from '~~/common/utils/helper.js';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';

  export default {
    name: 'AwNotificationItem',
    components: {
      LvGrid,
      LvIcon,
      LvButton,
      AwBasketProductListItem: defineAsyncComponent(() => import('~~/common/components/Common/BasketProductList/AwBasketProductListItem')),
    },
    props: {
      index: {
        type: String,
        required: true,
      },
      /**
       * @param {
       * {
       *    type: String,
       *    iconName: String,
       *    manualClose: Boolean,
       *    text: Object: {
       *      title: String,
       *      subtitle: String,
       *    }
       * }} notification
       */
      notification: {
        type: Object,
        default: () => ({ text: {} }),
      },
      close: {
        type: Function,
        default: () => [],
      },
      thinContent: {
        type: Boolean,
        default: false,
      },
      extraThinContent: {
        type: Boolean,
        default: false,
      },
      thickContent: {
        type: Boolean,
        default: false,
      },
      inlinedContent: {
        type: Boolean,
        default: false,
      },
      contentAlignment: {
        type: String,
        default: 'top',
      },
      isMarginLess: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'close-notification-item',
      'dispatch-callback-success',
      'call-resolve-method',
    ],
    data () {
      return {
        show: true,
        AnaliticsClasses,
      };
    },
    computed: {
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      notificationTexts () {
        return notificationTokenizer({ text: this.notification?.text }, { awt: this.$awt });
      },
      itemErrors () {
        return this.notification?.errorData?.errors?.map(error => error.itemReferences?.map(itemReference => ({
          ...itemReference,
          id: itemReference.objectId,
          variant: {
            media: itemReference.media,
            name: itemReference.name,
            id: itemReference.objectId,
            productId: itemReference.objectId,
            cartInfo: {
              quantityStepSize: itemReference.quantity.quantity,
              quantitySteps: 1,
            },
          },
        })))
          .flat()
          .filter(item => item);
      },
    },
    methods: {
      resolveClose (k) {
        if (this.notification.promise?.[k]) {
          this.notification.promise[k].cb(k);
        }
        this.close(this.index);
      },
      closeSelf () {
        this.show = false;
        this.$emit('close-notification-item');
      },
      async callAction () {
        // if store action stored
        if (this.notification?.apiData?.storeAction) {
          const result = await this.notification.apiData.storeAction({
            ...this.notification?.apiData?.storeActionParameters,
            ...{
              resolveOption: 'auto',
            },
          });
          this.$emit('dispatch-callback-success', result);
        } else { // send back through an emit the complete notification object. we can use this for call custom resolve method
          this.$emit('call-resolve-method', this.notification);
        }
        this.closeSelf();
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.notificationItem {
  font-family: $secondary-font;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  display: flex;
  margin: 5px 0;
  border-radius: 12px;

  @include tablet(min) {
    font-size: 14px;
    line-height: 20px;
  }

  &:first-child {
    margin-top: 20px;
  }

  &default {
    color: $color-text-primary;
    background: $color-seashell;
  }

  &info {
    color: $color-notification-info-text;
    background: $color-notification-info--12;
  }

  &error {
    color: $color-notification-error-text;
    background: $color-notification-error--12;
  }

  &warning {
    color: $color-notification-warning-text;
    background: $color-notification-warning--12;
  }

  &success, &auchan {
    color: $color-notification-success-text;
    background: $color-notification-success--12;
  }

  &Circle {
    margin-bottom: 18px;
    padding: 21px;
    border-radius: 100%;

    @include tablet(min) {
      margin-bottom: 20px;
    }

    &.info {
      background-color: $color-notification-info--12;
    }

    &.error {
      background-color: $color-notification-error--12;
    }

    &.warning {
      background-color: $color-notification-warning--12;
    }

    &.success {
      background-color: $color-notification-success--12;
    }
  }

  &Icon {
    width: 15px;
    height: 15px;
    color: $color-white;

    &default {
      fill: $color-pelorous;
    }

    &info {
      color: $color-plum;
    }

    &error {
      color: $color-strawberry;
    }

    &warning {
      color: $color-marigold;
    }

    &success {
      color: $color-sage;
    }

    &auchan {
      fill: $color-sage;
      color: $color-sage;
    }

    &Thick {
      width: 22px;
      height: 22px;
    }
  }

  &Content {
    flex-grow: 1;
    padding: 16px 18px;

    &.top {
      /* default */
    }

    &.center {
      display: flex;
      align-items: center;
    }

    &Thin {
      padding: 12px 20px;
    }

    &ExtraThin {
      padding: 8px;
    }

    &Thick {
      padding: 40px;
      text-align: center;

      .notificationItemContentSubtitle {
        font-size: 14px;
        line-height: 20px;

        @include tablet(min) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    &Inlined {
      line-height: 16px;
      padding: 18px 16px 16px;

      @include tablet(min) {
        line-height: 20px;
      }
    }

    &Title {
      font-weight: $font-weight-bold-v2;
    }

    &Subtitle {
      font-weight: $font-weight-regular-v2;

      @include tablet(min) {
        font-weight: $font-weight-normal-v2;
      }

      strong {
        font-weight: $font-weight-bold-v2;
      }
    }

    &Footer {
      font-size: 9px;
      position: absolute;
      right: 4px;
      bottom: -3px;
      color: $color-silver-chalice;
    }
  }

  &Close {
    position: absolute;
    top: 0;
    right: 7px;
    bottom: auto;
    left: auto;
    width: 30px;
    padding: 8px;
    text-align: center;
    border: none;
    background: $color-white-transparent;
  }

  &MarginLess {
    margin: 0 !important;
  }

  .actionButtons {
    margin-top: 20px;

    button:not(:last-child) {
      @include tablet(min) {
        margin-right: 8px;
      }
      @include mobile(max) {
        margin-bottom: 16px;
      }
    }
  }

  .productWrapper {
    overflow-y: auto;
    max-height: 450px;
    margin-top: 32px;
    text-align: left;
    border: 1px solid $color-mercury-alt;
    border-radius: 12px;
  }
}
</style>
